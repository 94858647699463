<template>
  <!--a pop 2 遲到人士的人需要先設定的時間-->
  <div :class="{'modal-ui': true, 'is-active': this.$store.state.modalUI.modalActive.pop2}">
    <div class="modal-ui__overlay">
      <div class="modal-ui__inner">
        <header class="modal-ui__header">
          <h3>帶位安排</h3>
          <button class="modal-ui__btn-close" @click="closePop('2')"></button>
        </header>
        <div class="modal-ui__content">
          <div class="modal-ui__row">
            <h4>設定時間</h4>
            <input class="modal-ui__input-time" type="time" :value="setTime" @input="setTime = $event.target.value">
          </div>
          <div class="modal-ui__row">
            <p class="modal-ui__text">將於<strong>{{setTime}}</strong>分開始進行。</p>
          </div>
          <div class="modal-ui__row">
            <button class="modal-ui__btn-next" @click="onPop2Next">下一步</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--a pop 2 遲到人士的人需要先設定的時間-->
  <!--a pop 2a 帶位安排-->
  <div :class="{'modal-ui': true, 'is-active': this.$store.state.modalUI.modalActive.pop2a}">
    <div class="modal-ui__overlay">
      <div class="modal-ui__inner">
        <header class="modal-ui__header">
          <h3>帶位安排</h3>
          <div class="modal-ui__filter">
            <div class="modal-ui__filter-group">
              <button class="modal-ui__btn-filter" :class="{ 'is-active': popJobsIsActive}" @click="togglePopJobs">{{selectJobName}}</button>
              <div class="modal-ui__sub-nav" :class="{ 'is-active': popJobsIsActive}">
                <button v-for="(job, index) in jobs" :key="index" class="modal-ui__btn-option" @click="onSelectJob(job.id)">{{job.name}}</button>
              </div>
            </div>
            <div class="modal-ui__filter-group">
              <button class="modal-ui__btn-filter" :class="{ 'is-active': popSpecialtiesIsActive }" @click="togglePop2aSpecialties">{{selectSpecialtyName}}</button>
              <div class="modal-ui__sub-nav" :class="{ 'is-active': popSpecialtiesIsActive }" >
                <button v-for="(specialty, index) in specialties" :key="index" class="modal-ui__btn-option" @click="onSelectSpecialty(specialty.id)">{{specialty.name}}</button>
              </div>
            </div>
          </div>
          <div class="site-search">
            <input type="text" v-model="keyword">
            <button>搜尋</button>
          </div>
          <button class="modal-ui__btn-close" @click="closePop('2a')"></button>
        </header>
        <div class="modal-ui__content">
          <div class="modal-ui__row">
            <h4>選擇人員</h4>
          </div>
          <div class="modal-ui__employee-list">
            <button v-for="(staff, index) in filteredStaffs" :key="index" class="modal-ui__btn-name" :class="{ 'is-active': staff.id === selectStaffId }"  @click="onSelectStaff(staff.id)">{{staff.fullName}}</button>
          </div>
          <div class="modal-ui__row">
            <button class="modal-ui__btn-next" @click="onPop2aNext">下一步</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--a pop 2a 帶位安排-->
  <!--a pop 2b 帶位安排-->
  <div :class="{'modal-ui': true, 'is-active': this.$store.state.modalUI.modalActive.pop2b}">
    <div class="modal-ui__overlay">
      <div class="modal-ui__inner">
        <header class="modal-ui__header">
          <h3>帶位安排</h3>
          <button class="modal-ui__btn-close" @click="closePop('2b')"></button>
        </header>
        <div class="modal-ui__content">
          <div class="modal-ui__row">
            <h4>目的地：{{spotType==='ROOM'?'診間':'休息區'}}</h4>
          </div>
          <div class="modal-ui__office-list">
            <button v-for="(spot, index) in spots" :key="index" class="modal-ui__btn-office" :class="{ 'is-active': spot.id === selectSpotId }"  @click="onSelectSpot(spot.id)">{{spot.name}}({{spot.location}})</button>
          </div>
          <div class="modal-ui__row">
            <button class="modal-ui__btn-confirm" @click="onPop2bConfirm">確定</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--a pop 2b 帶位安排-->
  <!--a pop 4a 人員替換-->
  <div :class="{'modal-ui': true, 'is-active': this.$store.state.modalUI.modalActive.pop4a}">
    <div class="modal-ui__overlay">
      <div class="modal-ui__inner">
        <header class="modal-ui__header">
          <h3>人員替換</h3>
          <div class="modal-ui__filter">
            <div class="modal-ui__filter-group">
              <button class="modal-ui__btn-filter" :class="{ 'is-active': popJobsIsActive}" @click="togglePopJobs">{{selectJobName}}</button>
              <div class="modal-ui__sub-nav" :class="{ 'is-active': popJobsIsActive}">
                <button v-for="(job, index) in jobs" :key="index" class="modal-ui__btn-option" @click="onSelectJob(job.id)">{{job.name}}</button>
              </div>
            </div>
            <div class="modal-ui__filter-group">
              <button class="modal-ui__btn-filter" :class="{ 'is-active': popSpecialtiesIsActive }" @click="togglePop2aSpecialties">{{selectSpecialtyName}}</button>
              <div class="modal-ui__sub-nav" :class="{ 'is-active': popSpecialtiesIsActive }" >
                <button v-for="(specialty, index) in specialties" :key="index" class="modal-ui__btn-option" @click="onSelectSpecialty(specialty.id)">{{specialty.name}}</button>
              </div>
            </div>
          </div>
          <div class="site-search">
            <input type="text" v-model="keyword">
            <button>搜尋</button>
          </div>
          <button class="modal-ui__btn-close" @click="closePop('4a')"></button>
        </header>
        <div class="modal-ui__content">
          <div class="modal-ui__row">
            <h4>選擇人員</h4>
          </div>
          <div class="modal-ui__employee-list">
            <button v-for="(staff, index) in filteredStaffs" :key="index" class="modal-ui__btn-name" :class="{ 'is-active': staff.id === selectStaffId }"  @click="onSelectStaff(staff.id)">{{staff.fullName}}</button>
          </div>
          <div class="modal-ui__row">
            <button class="modal-ui__btn-cancel" @click="closePop('4a')">取消</button>
            <button class="modal-ui__btn-confirm" @click="onPop4aConfirm">確定</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--a pop 4a 人員替換-->
  <!--a pop 6a 點滴選單a-->
  <div :class="{'modal-ui': true, 'is-active': this.$store.state.modalUI.modalActive.pop6a}">
    <div class="modal-ui__overlay">
      <div class="modal-ui__inner">
        <header class="modal-ui__header">
          <h3>點滴列表</h3>
          <button class="modal-ui__btn-close" @click="closePop('6a')"></button>
        </header>
        <div class="modal-ui__content">
          <div class="modal-ui__iv">
            <div class="modal-ui__row">
              <h4>已設定的點滴</h4>
            </div>
            <div class="modal-ui__iv-list">
              <button v-for="(order, index) in infusionOrders" :key="index" class="modal-ui__btn-iv" @click="onInfusionOrder(order)">{{order.infusionName}}</button>
            </div>
          </div>
          <div class="modal-ui__row" v-if="infusionOrders.length < 2">
            <button class="modal-ui__btn-confirm" @click="onNewInfusionOrder">新增</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--a pop 6a 點滴選單a-->
  <!--a pop 6 點滴選單-->
  <div :class="{'modal-ui': true, 'is-active': this.$store.state.modalUI.modalActive.pop6}">
    <div class="modal-ui__overlay">
      <div class="modal-ui__inner">
        <header class="modal-ui__header">
          <h3>點滴內容選單</h3>
          <button class="modal-ui__btn-close" @click="onClose6"></button>
        </header>
        <div class="modal-ui__content scroll">
          <div class="modal-ui__iv">
            <div class="modal-ui__row">
              <h4>點滴名稱</h4>
            </div>
            <div class="modal-ui__iv-list">
              <button v-for="(infusion, index) in infusions" :key="index" class="modal-ui__btn-iv" :class="{ 'is-active': selectInfusionId === infusion.id }" @click="onSelectInfusion(infusion.id)">{{infusion.name}}</button>
            </div>
          </div>
          <div class="modal-ui__iv">
            <div class="modal-ui__row">
              <h4>配方加強</h4>
            </div>
            <div class="modal-ui__iv-list">
              <button v-for="(ingredient, index) in ingredients" :key="index" class="modal-ui__btn-iv" :class="{ 'is-active': selectedIngredients.includes(ingredient.id)}" @click="onSelectIngredient(ingredient.id)">{{ingredient.name}}</button>
            </div>
          </div>
          <div class="modal-ui__row">
            <h4>設定時間</h4>
            <input class="modal-ui__input-time" type="time" :value="setTime" @input="setTime = $event.target.value">
          </div>
          <div class="modal-ui__row">
            <p class="modal-ui__text">將於<strong>{{setTime}}</strong>分開始進行。</p>
          </div>
          <div class="modal-ui__row">
            <button class="modal-ui__btn-delete" v-if="deleteInfusionOrderButtonActive" @click="onDeleteInfusion">刪除</button>
            <button class="modal-ui__btn-confirm" @click="onConfirmInfusion">確定</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { apiGetSpots, apiGetJobs, apiGetSpecialties, apiNewInfusionOrder, apiDeleteInfusionOrder, apiUpdateInfusionOrder, apiModifyTask } from '../api/v1/reservations'
import { formatTimeHHmm } from '../utils/utils'

export default {
  name: 'ModalUI',
  props: ['reservation', 'spotType', 'task'],
  emits: ['on-take-to-spot'],
  data () {
    return {
      keyword: '',
      setTime: '',
      selectStaffId: 0,
      selectSpotId: 0,
      selectJobId: 0,
      selectSpecialtyId: 0,
      selectInfusionId: 0,
      selectInfusionOrderId: 0,
      popJobsIsActive: false, // 身份選單開關
      popSpecialtiesIsActive: false, // 專長選單開關
      spots: [],
      jobs: [],
      specialties: [],
      selectedIngredients: []
    }
  },
  created () {
  },
  computed: mapState({
    staffs: state => state.modalUI.staffs,
    filteredStaffs: function () {
      if (this.staffs) {
        return this.staffs.filter(staff => {
          return staff.fullName.includes(this.keyword)
        }).filter(staff => {
          return (this.selectJobId === 0) || (staff.jobId === this.selectJobId)
        }).filter(staff => {
          return (this.selectSpecialtyId === 0) || (staff.specialties.map(s => s.id).includes(this.selectSpecialtyId))
        })
      }
      return []
    },
    selectJobName () { return this.selectJobId === 0 ? '身份' : this.jobs[this.selectJobId].name },
    selectSpecialtyName () { return this.selectSpecialtyId === 0 ? '專長' : this.specialties[this.selectSpecialtyId].name },
    infusionOrders: state => state.modalUI.infusionOrders,
    ingredients: state => state.modalUI.ingredients,
    infusions: state => state.modalUI.infusions,
    deleteInfusionOrderButtonActive () { return (this.selectInfusionOrderId > 0) }
  }),
  watch: {
    reservation: function (nv, ov) {
      console.log(`reservation changed to ${nv.id}`)
      this.resetData()
      this.setTime = formatTimeHHmm(new Date())
    }
  },
  methods: {
    // 清除選擇
    resetData () {
      this.selectStaffId = 0
      this.selectSpotId = 0
      this.selectJobId = 0
      this.selectSpecialtyId = 0
      this.selectInfusionId = 0
      this.selectInfusionOrderId = 0
    },
    // 從「選擇時間」前往「選擇人員」
    async onPop2Next () {
      this.closePop('2')
      this.resetData()
      this.showPop({ modalId: '2a' })
    },
    onPop2aNext () {
      this.closePop('2a')
      apiGetSpots({}).then(data => {
        console.log(data)
        if (data.error) {
          console.log(data.error.message)
        } else {
          this.spots = data.filter(spot => spot.type === this.spotType)
          this.showPop({ modalId: '2b' })
        }
      })
    },
    onPop2bConfirm () {
      this.closePop('2b')
      console.log(`time: ${this.setTime}, staff: ${this.selectStaffId} spot: ${this.selectSpotId}`)
      const data = { staffId: this.selectStaffId, spotId: this.selectSpotId, time: this.setTime }
      this.$emit('on-take-to-spot', { reservationId: this.reservation.id, data: data })
    },
    async togglePopJobs () {
      // 開啟身份選單前先讀取資料
      if (!this.popJobsIsActive) {
        await apiGetJobs().then(data => {
          console.log(data)
          if (data.error) {
            console.log(data.error.message)
          } else {
            data.unshift({ id: 0, name: '全部' })
            this.jobs = data
            this.popJobsIsActive = true
          }
        })
      } else {
        this.popJobsIsActive = false
      }
    },
    async togglePop2aSpecialties () {
      // 開啟專長選單前先讀取資料
      if (!this.popSpecialtiesIsActive) {
        await apiGetSpecialties().then(data => {
          console.log(data)
          if (data.error) {
            console.log(data.error.message)
          } else {
            data.unshift({ id: 0, name: '全部' })
            this.specialties = data
            this.popSpecialtiesIsActive = true
          }
        })
      } else {
        this.popSpecialtiesIsActive = false
      }
    },
    onSelectSpot (spotId) {
      this.selectSpotId = spotId
    },
    onSelectStaff (staffId) {
      this.selectStaffId = staffId
    },
    onSelectJob (jobId) {
      this.selectJobId = jobId
      this.popJobsIsActive = false
    },
    onSelectSpecialty (specialtyId) {
      this.selectSpecialtyId = specialtyId
      this.popSpecialtiesIsActive = false
    },
    onSelectInfusion (infusionId) {
      if (!this.deleteInfusionOrderButtonActive) {
        this.selectInfusionId = infusionId
      }
    },
    onSelectIngredient (ingredientId) {
      const index = this.selectedIngredients.indexOf(ingredientId)
      if (index > -1) {
        this.selectedIngredients.splice(index, 1)
      } else {
        this.selectedIngredients.push(ingredientId)
      }
    },
    onInfusionOrder (order) {
      console.log(`select order id ${order.id}`)
      this.selectInfusionOrderId = order.id
      this.selectInfusionId = order.infusionId
      this.selectedIngredients = order.ingredients.map(s => s.id)
      this.setTime = order.time
      this.closePop('6a')
      this.showPop({ modalId: '6' })
    },
    onNewInfusionOrder () {
      this.selectInfusionOrderId = 0
      this.selectInfusionId = 0
      this.selectedIngredients = []
      this.closePop('6a')
      this.showPop({ modalId: '6' })
    },
    onDeleteInfusion () {
      const params = {
        reservationId: this.reservation.id,
        infusionOrderId: this.selectInfusionOrderId
      }
      apiDeleteInfusionOrder(params).then(data => {
        this.onClose6()
      })
    },
    onConfirmInfusion () {
      const params = {
        reservationId: this.reservation.id,
        infusionOrderId: this.selectInfusionOrderId,
        data: {
          infusionId: this.selectInfusionId,
          ingredientIds: this.selectedIngredients,
          time: this.setTime
        }
      }
      const api = (this.selectInfusionOrderId > 0 ? apiUpdateInfusionOrder : apiNewInfusionOrder)
      api(params).then(data => {
        if (data.error) {
          console.log(data.error.message)
        } else {
          this.onClose6()
        }
      })
    },
    onPop4aConfirm () {
      if (this.selectStaffId === 0) {
        return
      }
      // 確定換人
      const params = {
        reservationId: this.reservation.id,
        taskId: this.task.id,
        data: {
          staffId: this.selectStaffId
        }
      }
      console.log('onPop4aConfirm PARAM')
      console.log(params)
      apiModifyTask(params).then(data => {
        if (data.error) {
          console.log(data.error.message)
        } else {
          this.closePop('4a')
        }
      })
    },
    onClose6 () {
      this.closePop('6')
      this.showPop({ modalId: '6a', reservationId: this.reservation.id })
    },
    ...mapActions(
      'modalUI', ['showPop', 'closePop']
    )
  }
}
</script>
<style lang="scss">
.modal-ui {
  &__btn-delete {
    background-color: #f52b08;
    width: 12rem;
    text-align: center;
    line-height: 4rem;
    height: 4rem;
    text-align: center;
    border-radius: 4rem;
    color: #fff;
    border: 0;
    font-size: 1.8rem;
    margin: 0 auto;
    display: block;
    transition: all 300ms ease;
    &:active {
      background-color: #b6b6b6;
    }
  }
  &__btn-cancel {
    background-color: #ffffff;
    width: 12rem;
    text-align: center;
    line-height: 4rem;
    height: 4rem;
    text-align: center;
    border-radius: 4rem;
    color: #525252;
    border: 0;
    font-size: 1.8rem;
    margin: 0 auto;
    display: block;
    transition: all 300ms ease;
    &:active {
      background-color: #b6b6b6;
    }
  }
}
</style>
